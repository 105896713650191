.view-locations {
  display: flex;
  flex-direction: column;
  margin: 24px 46px 24px 24px;

  &-details {
    display: flex;
    align-items: flex-start;

    &-img img {
      height: 205px;
      width: 205px;
    }

    &-info1, &-info2 {
      display: flex;
      flex-direction: column;
    }

    &-info1 {
      width: 446px;
      //margin-right: 128px;
    }

    &-info2 {
      width: 352px;
    }

    &-info {
      display: flex;
      margin-left: 30px;
      &-row {
        display: flex;
        margin-bottom: 14px;
      }

      &-row:last-child{
        margin-bottom: unset;
      }

      &-label {
        font-size: 18px;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-align: left;
        width: 41%;
      }

      &-value {
        font-size: 16px;
        letter-spacing: 0px;
        color: #838383;
        text-align: left;
        width: 50%;

        &-notes {
          height: 80px;
          overflow-y: auto;
          white-space: pre-wrap;
          width: 95%;
          background-color: #16171b;
          padding: 15px;
          margin-top: 15px;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #bec4ca;
            border-radius:3px;
          }
        }
      }
    }

    &-info3 {
      width: 376px;
      & .view-locations-details-info-row {
        flex-direction: column;
      }
    }

    &-link {
      text-align: left;
      text-decoration: underline;
      color: #159f15;
      font-size: 16px;
      cursor: pointer;
    }
  }

  &-heading {
    margin: 30px 0 20px 0;
    font-size: 20px;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    text-align: left;
  }

  &-sites {
    display: flex;
    flex-direction: column;
  }

  &-site {
    display: flex;
    background: #16171B 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0 27px;
    align-items: center;
    height: 99px;
    justify-content: space-between;

    &-info-container {
      display: flex;
      width: 34%;
      justify-content: space-between;
      align-items: center;
    }

    &-card {
      display: flex;
      background: #222224 0% 0% no-repeat padding-box;
      border: 1px solid #303139;
      border-radius: 5px;
      width: 176px;
      height: 28px;
      align-items: center;
      justify-content: space-between;
      padding: 14px;

      & .view-locations-site-value {
        margin-left: 16px;
      }
    }

    &-label {
      font-size: 18px;
      color: #fff;
      letter-spacing: 0.36px;
      display: flex;
      align-items: center;
    }

    &-value {
      font-size: 16px;
      letter-spacing: 0.32px;
      color: #838383;
    }
  }

  & button{
    background-color: transparent;
    img {
      height: 30px;
      width: 30px;
    }
  }
}

.view-locations-site-info {
  & .view-locations-site-value {
    text-align: left;
  }
}

