.locations {
  background-color: #222224;
  display: flex;
  flex-direction: column;

  & table {
    th:first-child {
      border-top-left-radius: 8px;
    }

    th:last-child {
      border-top-right-radius: 8px;
    }

    th:nth-child(1) {
      width: 6%;
    }

    th:nth-child(2) {
      width: 12%;
    }

    th:nth-child(3) {
      width: 15%;
    }

    th:nth-child(4) {
      width: 12%;
    }

    th:nth-child(5) {
      width: 14%;
    }

    th:nth-child(6) {
      width: 12%;
    }

    th:nth-child(7) {
      width: 17%;
    }

    th:nth-child(8) {
      width: 10%;
    }
  }

  &-filter {
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #303139;
    color: #D6D6D6;
    font-size: 14px;

    label:first-child {
      margin-left: 30px;
    }

    & select {
      width: 155px;
      height: 40px;
      background: #16171B 0% 0% no-repeat padding-box;
      border: none; //1px solid #303139;
      border-radius: 5px;
      color: #D6D6D6;
      margin-right: 50px;
      border-left: 10px solid #16171B;
      border-right: 18px solid #16171B;
      outline: none;

      & option {
        color: #D6D6D6;
        
      padding: 10px;
      }
    }

    & label {
      margin-right: 15px;
    }

    &-clear {
      background: none;
      color: #D6D6D6;
      border: 1px solid #303139;
      height: 40px;
      border-radius: 5px;
      padding: 0 20px;
      background: #16171B 0% 0% no-repeat padding-box;
    }
  }
  
  &-grid {
    &-logo {
      height: 33px;
      width: 33px;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 30px;

      & input {
        width: 310px;
        height: 39px;
        padding: 0 10px;
        color: #838383;
        background: #16171B 0% 0% no-repeat padding-box;
        border: 1px solid #303139;
        border-radius: 5px 0 0 5px;
        outline: none;
      }

      & button {
        background: #159F15 0% 0% no-repeat padding-box;
      }

      &-search {
        height: 42px;
        width: 45px;
        margin-right: 30px;
        border-radius: 0 5px 5px 0;
      }

      &-add {
        height: 42px;
        width: 40px;
        display: flex;
        justify-content: center;
        border-radius: 2px;
      }
    }

    &-row-actions {
      display: flex;

      & button {
        background-color: transparent;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
          height: 17px;
        }

        &:first-child {
          margin-right: 20px;
        }

        &:last-child {
          margin-left: 20px;
        }
      }
    }

    &-status {
      width: 80px;
      height: 30px;
      border-radius: 15px;
      font-size: 12px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #838383 0% 0% no-repeat padding-box;

      &-active {
        background: #159F15 0% 0% no-repeat padding-box;
      }
    }
  }
}