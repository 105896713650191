.vr-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  text-align:center;
}

.vr-tooltip .vr-tooltip-text {
  visibility: hidden;
  width: 175px;
  //height: 30px;
  background-color: #717171;
  color: #D6D6D6;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 80%;
  margin-left: -60px !important;
}

.vr-tooltip .vr-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 34%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #717171 transparent transparent transparent;
}

.vr-tooltip:hover .vr-tooltip-text {
  visibility: visible;
}