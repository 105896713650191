.vrfid-header {
  display: flex;
  z-index: 1;
  overflow-x: hidden;
  height: 85px;

  &-logo {
    width: 310px;
    border-right: 1px solid #303139;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-container {
    display: flex;
    width: calc(100% - 365px);
  }

  &-row {
    display: flex;
    color: #fff;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .app-logo {
    height: 54px;
    width: 166px;
    margin-right: 51px;
  }
}