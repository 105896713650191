.pass-details {
    display: flex;
    margin-top: 20px;
    background-color: #222224;
    display: flex;
    flex-direction: column;

    & table {
      th:first-child {
        border-top-left-radius: 8px;
      }
  
      th:last-child {
        border-top-right-radius: 8px;
      }
  
      th:nth-child(1) {
        width: 10%;
      }
  
      th:nth-child(2) {
        width: 20%;
      }
  
      th:nth-child(3) {
        width: 70%;
      }
    }
}