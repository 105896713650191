.asterisk::-webkit-input-placeholder {
  color:    #f00;
}
.asterisk:-moz-placeholder {
 color:    #f00;
 opacity:  1;
}
.asterisk::-moz-placeholder {
 color:    #f00;
 opacity:  1;
}
.asterisk:-ms-input-placeholder {
 color:    #f00;
}