.passholders {
  background-color: #222224;
  display: flex;
  flex-direction: column;

  & table {
    th:first-child {
      border-top-left-radius: 8px;
    }

    th:last-child {
      border-top-right-radius: 8px;
    }

    th:nth-child(1) {
      width: 6%;
    }

    th:nth-child(2) {
      width: 24%;
    }

    th:nth-child(3) {
      width: 18%;
    }

    th:nth-child(4) {
      width: 8%;
    }

    th:nth-child(5) {
      width: 12%;
    }

    th:nth-child(6) {
      width: 10%;
    }

    th:nth-child(7) {
      width: 14%;
    }

    th:nth-child(8) {
      width: 8%;
    }

  }

  &-filter {
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #303139;
    color: #D6D6D6;
    font-size: 14px;

    label:first-child {
      margin-left: 30px;
    }

    & .multiselect-dropdown span.placeholder {
      color: #fff;
    }

    & .multiselect-dropdown-list-wrapper {
      top: 46px;
      left: -7px;
      width: 184px;
    }

    & .multiselect-dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & select, & .multiselect-dropdown {
      width: 155px;
      height: 40px;
      background: #16171B 0% 0% no-repeat padding-box;
      border: 1px solid #303139;
      border-radius: 5px;
      color: #D6D6D6;
      margin-right: 50px;
      border-left: 10px solid #16171B;
      border-right: 18px solid #16171B;
      outline: none;
      cursor: pointer;

      & option {
        color: #D6D6D6;
        
      padding: 10px;
      }
    }

    & label {
      margin-right: 15px;
    }

    &-clear {
      background: none;
      color: #D6D6D6;
      border: 1px solid #303139;
      height: 40px;
      border-radius: 5px;
      padding: 0 20px;
      background: #16171B 0% 0% no-repeat padding-box;
    }
  }
  

  &-grid {
    &-logo {
      height: 33px;
      width: 33px;
    }
    &-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 72px;
      padding: 0 30px;
      color: #fff;
    }

    &-datatable {
      height: 100%;
      width: calc(100% - 90px);
      margin-left: 30px;
      font-size: 16px;

      & thead {
        color: #fff;
        height: 50px;
        background-color: #000;
        border-color: #222224;
        width: 100%;
        text-align: left;
        & th:first-child {
          padding-left: 30px;
        }

        & th:last-child {
          padding-right: 30px;
        }
      }

      & tbody {
        margin: 0 30px;
        background-color: #16171B;
      }

      & tr {
        height: 74px;
        border-bottom: 1px solid #303139;
        color: #838383;
        text-align: left;
        & td:first-child {
          padding-left: 30px;
        }

        & td:last-child {
          padding-right: 30px;
        }
      }
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 30px;

      & input {
        width: 310px;
        height: 39px;
        padding: 0 10px;
        color: #838383;
        background: #16171B 0% 0% no-repeat padding-box;
        border: 1px solid #303139;
        border-radius: 5px 0 0 5px;
        outline: none;
      }

      & button {
        background: #159F15 0% 0% no-repeat padding-box;
      }

      &-search {
        height: 42px;
        width: 45px;
        margin-right: 30px;
        border-radius: 0 5px 5px 0;
      }

      &-add {
        height: 42px;
        width: 40px;
        display: flex;
        justify-content: center;
        border-radius: 2px;
      }
    }

    &-row-actions {
      display: flex;

      & button {
        background-color: transparent;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
          height: 17px;
        }

        &:first-child {
          margin-right: 20px;
        }

        &:last-child {
          margin-left: 20px;
        }
      }
    }

    &-status {
      width: 80px;
      height: 30px;
      border-radius: 15px;
      font-size: 12px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #838383 0% 0% no-repeat padding-box;

      &-active {
        background: #159F15 0% 0% no-repeat padding-box;
      }
    }

    & .table-column {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      & span {
        display: flex;
        flex-direction: column;
        margin: 8px 0 0 8px;

        & .fa {
          opacity: 0.5;
          background-color: transparent !important;
        }

        & .active {
          opacity: 1;
        }
      }

      & .fa-info-circle {
        margin: 8px 0 0 8px;
      }
    }
  }
}