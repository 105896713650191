.dashboard {
  margin: 0 45px 0 22px;
  display: flex;
  flex-direction: column;
  color: #fff;

  &-title {
    text-align: left;
    color: #fff;
    margin: 22px 0;
  }

  &-cards {
    display: flex;
    justify-content: space-between;
  }

  &-card {
    width: 24%;
    height: 165px;
    background: #16171B 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-long {
      width: 32.5%;
    }

    &-content {
      display: flex;
      width: calc(100% - 26px);
      height: calc(100% - 26px);
      justify-content: space-between;
    }

    &-name {
      font-size: 22px;
      letter-spacing: 0.44px;
    }

    &-count {
      font-size: 35px;
      font-weight: bold;
      letter-spacing: 0.7px;
    }

    &-img img {
      width: 58px;
      height: 58px;
    }

    &-link {
      background: #159F1534 0% 0% no-repeat padding-box;
      border: 0.800000011920929px solid #159F15;
      border-radius: 5px;
      opacity: 1;
      width: 92px;
      height: 27px;
      display: flex;
      justify-content: center;
      align-items: center;

      & a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
      }
    }

    &-details {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: baseline;
    }
  }

  &-card-loading::after {
    content: "";
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
  
    to {
      transform: rotate(1turn);
    }
  }
}