.sidenav {
  height: 100%;
  width: 310px;
  z-index: 1;
  overflow-x: hidden;
  font-size: 16px;

  &-heading {
    color: #3B3B3B;
    font-size: 16px;
    padding: 6px 8px 6px 0px;
    height: 56px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 43px;
  }

  &-icons {
    width: 21px;
    margin-left: 46px;
    margin-right: 13px;

    &-arrow {
      position: absolute;
      right: 20px;
    }
  }

  &-active-link {
    color: #159f15 !important;
  }
}

.active, .sidenav-active-menu {
  background-color: #16251a !important;
  color: white;
}

.sidenav a, .dropdown-btn {
  z-index: 999;
  cursor: pointer;
  padding: 6px 8px 6px 0px;
  height: 56px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
  color: #159f15;
}

.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 80px;
}

.fa-caret-down {
  float: right;
  padding-right: 6px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}