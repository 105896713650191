.layout {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #16171B;

  &-header {
    height: 85px;
    border-bottom: 1px solid #303139;
  }

  &-page {
    display: flex;
  }

  &-sidenav {
    color: #fff;
  }

  &-content {
    width: 100%;
    min-height: calc(100vh - 88px);
    background-color: #222224;
  }
}