.warning-message {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #159F15 0% 0% no-repeat padding-box;
  border-left: 20px solid #159F15;
  opacity: 1;
  text-align: left;
  font-size: 16px;
  //letter-spacing: 0.32px;
  color: #FFFFFF;
}