.schedule-report {
  display: flex;
  margin: 0 30px;
  background-color: #222224;
  display: flex;
  flex-direction: column;

  &-heading {
    color: #FFFFFF;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;

    &-text {
      font-size: 16px;
      letter-spacing: 0.32px;

      &-toggle {
        letter-spacing: 0;
      }
    }

    &-hardware {
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }
  
  &-form {
      display: flex;
      flex-direction: row;

      &-content {
          display: flex;
          width: 50%;
          flex-direction: column;
      }

      &-logo {
          height: 63px;
          width: inherit;
          background-image: url('../../../assets/vrfid-logo.png');
          background-repeat: no-repeat;
          background-size: contain;
          margin-bottom: 30px;
      }

      &-input-dual {
        display: flex;
        justify-content: space-between;

        & input {
          width: 46% !important;
        }
      }

      &-input-phone {
        display: flex;
        & select {
          width: 100px !important;
        }

        & input {
          border-left: unset !important;
        }
      }


    &-input {
      border-radius: 5px;
      margin-bottom: 20px;

      & input[type=text], & input[type=time], & select, & .multiselect-dropdown {
          width: -webkit-fill-available;
          padding: 10px;
          height: 30px;
          color: #838383;
          border: 1px solid var(---303139-strokes-input-fields);
          border: 1px solid #303139;
          opacity: 1;
          background-color: #16171B;
          outline: none;
      }

      &-disabled {
        opacity: 0.5;
      }
    }

    &-input-time {
      color: #fff;
      width: 99px;
      & input {
        cursor: pointer;
      }
    }

    &-input-phone, &-input-select {
      border: 1px solid #303139;

      & input, & select {
        border: none;
        border-left: 1px solid #303139 !important;
      }
    }

    &-btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

      &-btn button {
          height: 50px;
          width: 273px;
          margin-top: 10px;
          margin-bottom: 30px;
          background: var(--unnamed-color-159f15) 0% 0% no-repeat padding-box;
          background: #159F15 0% 0% no-repeat padding-box;
          border-radius: 3px;
          opacity: 1;
          text-align: center;
          font-size: 16px;
          letter-spacing: 0px;
          opacity: 1;
          cursor: pointer;

          & a {
              text-decoration: none;
              color: #FFFFFF;
          }
      }

    &-input select {
      height: 52px;
      border-right: 16px solid transparent;
      color: #838383;
    }

    &-add-more {
      color: #838383;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 120px;

      & i {
        color: #fff;
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }

  &-timings {
    margin-left: 25px;
    &-heading {
      color: #fff;
      font-size: 16px;
      margin-bottom: 20px;
      text-align: left;
    }

    &-day {
      width: 220px;
      & span {
        color: #868686;
        font-size: 16px;
      }

      & .selected {
        color: #fff;
      }
    }

    & input {
      padding: 10px 20px;
      margin-bottom: unset;
      font-size: 16px;
    }

    & input[type="checkbox"] {
      width: 18px;
      height: 18px;
      margin-right: 35px;
      cursor: pointer;
    }

    &-container {
      display: flex;
      cursor: pointer;
      & input {
        width: 20px;
        margin: unset;
        margin-right: 10px;
      }
    }

    &-hrs {
      margin-right: 80px;
      color: #868686;
      font-size: 16px;
      & input[type="checkbox"] {
        margin-right: 10px;
      }

      & .selected {
        color: #fff;
      }
    }

    &-to {
      font-size: 16px;
      margin: 0 40px;
      letter-spacing: 0px;
      color: #838383;
    }

    & .schedule-report-form-input {
      margin-bottom: unset;
    }

    &-row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}

.add-customer-form-btn-select {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.add-customer-form-btn-submit {
  float: right;
}

button {
  position: relative;
}

.button-loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button-loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}