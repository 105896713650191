.vrfid-datatable {
  background-color: #222224;
  display: flex;
  flex-direction: column;

  & td {
    word-break: break-all;
    padding: 5px;
  }

  .no-data-cell {
    text-align: center;
    background-color: #222224;
  }

  .export-dropdown {
    width: 155px;
    height: 40px;
    background: #16171B 0% 0% no-repeat padding-box;
    border: 1px solid #303139;
    border-radius: 5px;
    color: #D6D6D6;
    //margin-right: 50px;
    border-left: 10px solid #16171B;
    border-right: 18px solid #16171B;
    outline: none;
    margin: 0 30px 0 15px;

    & option {
      color: #D6D6D6;
      
    padding: 10px;
    }
  }

  .export-btn {
    margin-right: 30px;
    display: flex;
    align-items: center;

    & img {
      margin-right: 10px;
    }
  }

  &-filter {
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #303139;
    color: #D6D6D6;
    font-size: 14px;

    label:first-child {
      margin-left: 30px;
    }

    & select {
      width: 155px;
      height: 40px;
      background: #16171B 0% 0% no-repeat padding-box;
      border: 1px solid #303139;
      border-radius: 5px;
      color: #D6D6D6;
      margin-right: 50px;
      border-left: 10px solid #16171B;
      border-right: 18px solid #16171B;
      outline: none;

      & option {
        color: #D6D6D6;
        
      padding: 10px;
      }
    }

    & label {
      margin-right: 15px;
    }

    &-clear {
      background: none;
      color: #D6D6D6;
      border: 1px solid #303139;
      height: 40px;
      border-radius: 5px;
      padding: 0 20px;
      background: #16171B 0% 0% no-repeat padding-box;
    }
  }
  
  &-grid {
    & .table-column, & th {
      font-weight: normal;
    }
    &-logo {
      height: 33px;
      width: 33px;
    }
    & input[type="checkbox"] {
      cursor: pointer;
    }
    &-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 72px;
      padding: 0 30px;
      color: #fff;
    }

    &-datatable {
      height: 100%;
      width: calc(100% - 90px);
      margin-left: 30px;
      font-size: 16px;

      & tr {
        height: 74px;
        border-bottom: 1px solid #303139;
        color: #838383;
        text-align: left;

        & td:last-child {
          padding-right: 30px;
        }
      }

      & thead {
        height: 50px;
        background-color: #000;
        border-color: #222224;
        width: 100%;
        text-align: left;

        & tr {
          height: 50px;
          color: #fff;
        }

        & th:last-child {
          padding-right: 30px;
        }
      }

      & tbody {
        margin: 0 30px;
        background-color: #16171B;
      }
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 30px;

      & input {
        width: 310px;
        height: 39px;
        padding: 0 10px;
        color: #838383;
        background: #16171B 0% 0% no-repeat padding-box;
        border: 1px solid #303139;
        border-radius: 5px 0 0 5px;
        outline: none;
      }

      & button {
        background: #159F15 0% 0% no-repeat padding-box;
      }

      &-search {
        height: 42px;
        width: 45px;
        margin-right: 30px;
        border-radius: 0 5px 5px 0;
      }

      &-add {
        height: 42px;
        width: 40px;
        display: flex;
        justify-content: center;
        border-radius: 2px;
      }
    }

    &-row-actions {
      display: flex;

      & button {
        background-color: transparent;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
          height: 17px;
        }

        &:first-child {
          margin-right: 20px;
        }

        &:last-child {
          margin-left: 20px;
        }
      }
    }

    &-status {
      width: 80px;
      height: 30px;
      border-radius: 15px;
      font-size: 12px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #838383 0% 0% no-repeat padding-box;

      &-active {
        background: #159F15 0% 0% no-repeat padding-box;
      }
    }

    & .table-column {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      & span {
        display: flex;
        flex-direction: column;
        margin: 8px 0 0 8px;

        & .fa {
          opacity: 0.5;
          background-color: transparent !important;
        }

        & .active {
          opacity: 1;
        }
      }

      & .fa-info-circle {
        margin: 8px 0 0 8px;
      }
    }
  }

  &-loading::after {
    content: "";
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
  
    to {
      transform: rotate(1turn);
    }
  }
}