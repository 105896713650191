.otp {
    background-color: #16171B;
    height: 100vh;
    display: flex;
    flex-direction: row;
    
    &-form {
        width: 37%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-content {
            display: flex;
            width: 64%;
            flex-direction: column;
            height: 95%;
            flex-direction: column;
            justify-content: center;
        }

        &-logo {
            height: 63px;
            width: inherit;
            background-image: url('../../assets/vrfid-logo.png');
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 30px;
        }

        &-label {
            text-align: left;
            //font-family: Open Sans;
            font-size: 28px;
            margin-bottom: 10px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }

        &-label-note {
            font-size: 20px;
            margin-bottom: 25px;
        }

        &-inputs-container {
            display: flex;
        }

        &-input input {
            width: -webkit-fill-available;
            width: 16px;
            padding: 20px;
            height: 10px;
            color: #FFFFFF;
            border: 1px solid var(---303139-strokes-input-fields);
            border: 1px solid #303139;
            border-radius: 5px;
            opacity: 1;
            background-color: #16171B;
        }

        &-input {
            margin-bottom: 30px;

            &-separator {
                color: #303139;
                margin: 16px 10px 0 10px;
            }
        }

        &-btn button {
            width: 100%;
            height: 50px;
            background: var(--unnamed-color-159f15) 0% 0% no-repeat padding-box;
            background: #159F15 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;
            text-align: center;
            //font-family: Open Sans;
            font-size: 16px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;

            & a {
                text-decoration: none;
                color: #FFFFFF;
            }
        }

        &-signup-btn button {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            color: #000000;
        }

        &-resend-otp {
            display: flex;
            justify-content: flex-start;
            //font-family: Open Sans;
            font-size: 16px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            & a {
                text-decoration: underline;
                //font-family: Open Sans;
                font-size: 16px;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
                margin-left: 5px;
            }
            margin-bottom: 30px;
        }

        &-or-label {
            margin: 15px 0;
            & h3 {
                width: 100%; 
                text-align: center; 
                border-bottom: 1px solid #838383; 
                line-height: 0.1em;
                margin: 10px 0 20px;
                & span { 
                    background:#16171B;
                    color: #838383;
                    padding:0 10px;
                    font-size: 14px;
                    //font-family: Open Sans;
                    letter-spacing: 0px; 
                }
             }
        }


    }
}
