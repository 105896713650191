.profile {
  display: flex;
  align-items: center;
  justify-content: left;

  &-pic {
    margin-right: 12px;
    display: flex;
    height: 40px;
    width: 40px;
  }

  &-details {
    display: flex;
    flex-direction: column;
    justify-content: right;
    color: #ffff;
    text-transform: capitalize;
  }

  &-name {
    text-align: left;
    text-transform: none;
  }

  &-icon {
    color: #ffff;
    margin-left: 15px;
  }

  &-menu {
    width: 211px;
    div:nth-child(3) {
      width: 211px;
      background-color: #222224;
      color: #ffff;
      //border: 1px solid #303139;
    }
  }
}

#basic-button {
  padding: unset;
}