.set-password {
    background-color: #16171B;
    height: 100vh;
    display: flex;
    flex-direction: row;
    padding: 0 140px;

    &-background {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &-form {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;

        &-content {
            display: flex;
            flex-direction: column;
            width: 58%;
            height: 95%;
            flex-direction: column;
            justify-content: center;
            margin-right: 48px;
        }

        &-logo {
            height: 63px;
            width: inherit;
            background-image: url('../../../assets/vrfid-logo.png');
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 30px;
        }

        &-label {
            text-align: left;
            font-size: 20px;
            margin-bottom: 10px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }

        &-label-note {
            font-size: 16px;
            margin-bottom: 30px;
            font-weight: normal;
        }

        &-input input {
            width: -webkit-fill-available;
            padding: 10px;
            height: 30px;
            color: #FFFFFF;
            border: none;
            opacity: 1;
            background-color: #16171B;
        }

        &-input {
            margin-bottom: 30px;

            &-password {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        &-btn button {
            width: 100%;
            height: 50px;
            background: var(--unnamed-color-159f15) 0% 0% no-repeat padding-box;
            background: #159F15 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;
            text-align: center;
            //font-family: Open Sans;
            font-size: 16px;
            letter-spacing: 0px;
            opacity: 1;

            & a {
                text-decoration: none;
                color: #FFFFFF;
            }
        }

        .password-toggle-icon {
            position: absolute;
            cursor: pointer;
            margin-right: 1%;
        }
    }
}

  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #303139;
    border-radius: 5px;

    & .fa {
        font-size: 22px;
    }
  }
  
  .icon {
    background: #16171B;
    color: #838383;
    min-width: 50px;
    text-align: center;
  }
