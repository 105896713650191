.signin {
    background-color: #16171B;
    height: 100vh;
    display: flex;
    flex-direction: row;
    
    &-form {
        width: 37%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-content {
            display: flex;
            width: 64%;
            flex-direction: column;
            height: 95%;
            flex-direction: column;
            justify-content: center;
        }

        &-logo {
            height: 63px;
            width: inherit;
            background-image: url('../../assets/vrfid-logo.png');
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 30px;
        }

        &-label {
            text-align: left;
            //font-family: Open Sans;
            font-size: 20px;
            margin-bottom: 30px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }

        &-input input {
            width: -webkit-fill-available;
            padding: 10px;
            height: 30px;
            color: #FFFFFF;
            border: none;
            opacity: 1;
            background-color: #16171B;
        }

        &-input {
            margin-bottom: 30px;

            &-password {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        &-btn button {
            width: 100%;
            height: 50px;
            background: var(--unnamed-color-159f15) 0% 0% no-repeat padding-box;
            background: #159F15 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;
            text-align: center;
            //font-family: Open Sans;
            font-size: 16px;
            letter-spacing: 0px;
            opacity: 1;

            & a {
                text-decoration: none;
                color: #FFFFFF;
            }
        }

        &-signup-btn button {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            color: #000000;

            & a {
                color: #000000;
            }
        }

        &-forgot-link{
            display: flex;
            justify-content: flex-end;
            & a {
                text-align: right;
                text-decoration: underline;
                //font-family: Open Sans;
                font-size: 16px;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
            }
            margin-bottom: 30px;
        }

        &-or-label {
            margin: 15px 0;
            & h3 {
                width: 100%; 
                text-align: center; 
                border-bottom: 1px solid #838383; 
                line-height: 0.1em;
                margin: 10px 0 20px;
                & span { 
                    background:#16171B;
                    color: #838383;
                    padding:0 10px;
                    font-size: 14px;
                    //font-family: Open Sans;
                    letter-spacing: 0px; 
                }
             }
        }

        .password-toggle-icon {
            position: absolute;
            cursor: pointer;
            margin-right: 1%;
        }
    }

    & .form-input-error {
        margin-bottom: 15px;
    }
}

button {
    position: relative;
  }
  
  .button-loading .button__text {
    visibility: hidden;
    opacity: 0;
  }
  
  .button-loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
  
    to {
      transform: rotate(1turn);
    }
  }

  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #303139;
    border-radius: 5px;

    & .fa {
        font-size: 22px;
    }
  }
  
  .icon {
    background: #16171B;
    color: #838383;
    min-width: 50px;
    text-align: center;
  }
