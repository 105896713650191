.signup {
    background-color: #16171B;
    height: 100vh;
    display: flex;
    flex-direction: row;
    
    &-form {
        width: 37%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-input-error {
          text-align: left;
        }

        &-content {
            display: flex;
            width: 64%;
            flex-direction: column;
            height: 95%;
            flex-direction: column;
            justify-content: center;
        }

        &-logo {
            height: 63px;
            width: inherit;
            background-image: url('../../assets/vrfid-logo.png');
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 20px;
        }

        &-label {
            text-align: left;
            //font-family: Open Sans;
            font-size: 20px;
            margin-bottom: 20px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }

        &-label-note {
            font-size: 16px;
            margin-bottom: 30px;
        }

        &-input input, &-input select {
            width: -webkit-fill-available;
            padding: 10px;
            height: 30px;
            color: #FFFFFF;
            border: 1px solid var(---303139-strokes-input-fields);
            border: 1px solid #303139;
            border-radius: 5px;
            opacity: 1;
            background-color: #16171B;
        }

        &-input-country {
          border: 1px solid #303139;
          border-radius: 5px;
          & select {
            border: unset;
            outline: none;
          }
        }

        &-input {
            margin-bottom: 30px;
            &-error {
              margin-bottom: 10px;
            }
        }

        &-btn button {
            width: 100%;
            height: 50px;
            background: var(--unnamed-color-159f15) 0% 0% no-repeat padding-box;
            background: #159F15 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;
            text-align: center;
            //font-family: Open Sans;
            font-size: 16px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;

            & a {
                text-decoration: none;
                color: #FFFFFF;
            }
        }
    }

    & .input-container {
      display: -ms-flexbox; /* IE10 */
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid #303139;
      border-radius: 5px;
  
      & .fa {
          font-size: 22px;
      }
    }
    
    & .icon {
      background: #16171B;
      color: #838383;
      min-width: 50px;
      text-align: center;
    }
}

button {
  position: relative;
}

.button-loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button-loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}