.forgot-backdrop {
    width: 100%;
    height: 88%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url('../../../assets/forgot-password.png');
    background-repeat: no-repeat;
    background-size: contain;
}