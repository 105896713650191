@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.App {
  text-align: center;
  font-family: Open sans;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: left;
}

button, a {
  cursor: pointer;
  border: none;
}

input {
  width: -webkit-fill-available;
  outline: none;
}

/*.input-error {
  border: 1px solid red !important;
}*/

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus, 
select:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #838383;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #16171B;
    background-color: #16171B;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  background: #16171B 0% 0% no-repeat padding-box;
}

.password-toggle-icon {
  cursor: pointer;
}

select {
  cursor: pointer;
}

/* Button loading CSS */
button {
  position: relative;
}

.button-loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button-loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

input[type="checkbox"] {
  accent-color: #159F15
}

input[type=time] {
  color: #838383;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
}

input, select {
  color: #ffffff !important;
}

::placeholder {
  color: #ffffff;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #ffffff;
}

.keyValues-container {
  width: 950px; 
  overflow-x: scroll;
}

.keyValues-container::-webkit-scrollbar {
  height: 6px;
}

.keyValues-container::-webkit-scrollbar-thumb {
  background-color: #bec4ca;
  border-radius:3px;
}
