.signin-backdrop {
    border-right: 1px solid #303139;
    width: 63%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-img {
        height: 75%;
        width: inherit;
        background-image: url('../../assets/car-app.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
}